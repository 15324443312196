<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('studies.studies') }}</span>
				</v-card-title>
				<v-card-text>
					<v-list dense v-if="faculties">
						<v-list-item v-for="(faculty, index) in faculties" :key="index">
							<v-list-item-content>
								<span class="headline">{{ faculty.university.acronym }} - {{ faculty.acronym }}:</span>
								<template v-for="(degree, degreeIndex) in faculty.degrees">
									<span :key="degreeIndex" class="body-2" :style="{ color: degree.ended ? 'green' : '' }">
										[{{ degree.type }}] {{ degree.name }} ({{ degree.ended ? $t('studies.ended') : $t('studies.ongoing') }})
									</span>
								</template>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<template v-else>
						{{ $t('profile.noStudiesYet') }}
					</template>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserStudies',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			user: 'user/currentUser',
			faculties: 'faculties/faculties',
		}),
	},
	created() {
		this.loading = true
		this.fetchFaculties().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('faculties', ['fetchFaculties']),
	},
}
</script>
